export const TIMEOUT = 60000;

export const HOST_API = 'api.poketmenu.app';
export const PROTOCOL_HTTP = 'https';
export const ACCESS_TOKEN = 'feathers-jwt';
export const STORAGE = window.localStorage;
export const s3Path = 'https://menuapps3.s3.amazonaws.com';
export const s3PathImageHandrer = 'https://d1gup81n3r8p82.cloudfront.net';
export const HOST_FRONT = 'https://poketmenu.app';
export const LOGOAPP = '/static/icon-poketmenu.png';
